import "./select-uriage-dialog";
import "./uriage-edit";
import "./uriage-index";
import "./uriage-new";
import "./seikyusho-index";
import "./nyukinkeshikomi/NyukinkeshikomiIndex";
import "./omutsu-kyufu-tokuisaki";

import KojinmukeShimeSeikyushoIndex from "./kojinmukeshimeseikyusho/KojinmukeShimeSeikyushoIndex";
import MitsumorishoNew from "./mitsumorisho/MitsumorishoNew";
import MitsumorishoEdit from "./mitsumorisho/MitsumorishoEdit";

window.addEventListener('DOMContentLoaded', (event) => {
    KojinmukeShimeSeikyushoIndex.initComponent();
    MitsumorishoNew.initComponent();
    MitsumorishoEdit.initComponent();
});
